import { UpdateQuantity } from 'Shared/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

const useQuantitySelector = (
  currentQuantity: number,
  minQuantity: number,
  maxQuantity: number
) => {
  const { languageRoute } = useAppSettingsData();
  const disabledMax = currentQuantity === maxQuantity;
  const disabledMin = currentQuantity === minQuantity;

  const handleIncrement = (code: string) => {
    if (disabledMax) return;

    UpdateQuantity(code, 1, languageRoute);
  };

  const handleDecrement = (code: string) => {
    if (disabledMin) return;

    UpdateQuantity(code, -1, languageRoute);
  };

  return [handleIncrement, handleDecrement];
};
export default useQuantitySelector;
