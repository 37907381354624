import { useEffect, useState } from 'react';
import MotorcycleStockModel from 'Models/Variant/MotorcycleStockModel.interface';
import Fetcher from 'Shared/Common/Fetcher';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useSWR from 'swr';

let abortController: AbortController = new AbortController();
let hasMounted = false;
let cartUrl = '/api/cart/';

type CartReturnType = {
  motorcycleStock: MotorcycleStockModel;
};

export function GetMotorcycleStock(): CartReturnType {
  const tempCartObject: MotorcycleStockModel = {} as MotorcycleStockModel;
  const [motorcycleStock, setCart] =
    useState<MotorcycleStockModel>(tempCartObject);
  const { pageCacheTime } = useAppSettingsData();
  const { data } = useSWR(
    `${cartUrl}IsMotorcycleInStock`,
    FetchMotorcycleStock,
    {
      fallbackData: undefined,
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (data) {
        setCart(data);
      }
    }
  }, [data]);

  return { motorcycleStock };
}

function FetchMotorcycleStock(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  return Fetcher<MotorcycleStockModel, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data.motorcycleStock.result);
    },
    false
  );
}
