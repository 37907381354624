import { useState } from 'react';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import MotorcycleStockModel from 'Models/Variant/MotorcycleStockModel.interface';
import useProductImage from 'Shared/Hooks/useProductImage';

type CartReturnType = {
  itemImage: any;
  isMotorcycleOutOfStock: boolean;
  isConfirmationButtonVisible: boolean;
  setIsConfirmationButtonVisible: (arg: boolean) => void;
};

const useCartItem = (
  product: VariationModel,
  motorcycleStock: MotorcycleStockModel
): CartReturnType => {
  const [isConfirmationButtonVisible, setIsConfirmationButtonVisible] =
    useState<boolean>(false);

  const { itemImage } = useProductImage(product.image, product.variationImages);

  const isMotorcycleOutOfStock =
    product.code === motorcycleStock?.code && !motorcycleStock?.isInStock;

  return {
    itemImage,
    isMotorcycleOutOfStock,
    isConfirmationButtonVisible,
    setIsConfirmationButtonVisible,
  };
};
export default useCartItem;
