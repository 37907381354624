import { RemoveIcon } from 'Atoms/Icons';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';
import KexLink from 'Kex/KexLink';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Price from 'Atoms/Price/Price';
import { RemoveFromCart } from 'Shared/Cart/Cart';
import useQuantitySelector from './useQuantitySelector';
import useCartItem from './useCartItem';
import { MIN_QUANTITY } from '../../Shared/Common/QuantityLimit';
import ProductImage from 'Atoms/Image/ProductImage';
import { GetVariantDescriptions } from 'Shared/Common/VariationHelpers';
import { styled } from 'stitches.config';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Button from 'Atoms/Buttons/Button';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import SoldOut from './SoldOut';
import { ProductType } from 'Enums/ProductType.enum';
import MotorcycleStockModel from 'Models/Variant/MotorcycleStockModel.interface';
import ProductTag from 'Atoms/ProductTag/ProductTag';
import { useMemo } from 'react';

type PropTypes = {
  product: VariationModel;
  allowEdit: boolean;
  isMiniCart: boolean;
  needConfirmDelete?: boolean;
  motorcycleStock?: MotorcycleStockModel;
};

function CartItemDesktop({
  product,
  allowEdit,
  isMiniCart,
  needConfirmDelete = false,
  motorcycleStock = {} as MotorcycleStockModel,
}: PropTypes) {
  const { languageRoute } = useAppSettingsData();
  const {
    commonLabels: { abort: abortLabel },
    productLabels: {
      remove: removeLabel,
      confirmDeleteOfParent: confirmDeleteOfParentLabel,
    },
    cartLabels: { soldOut, soldOutContactMail, soldOutContactPhone },
  } = useTranslationData();

  const {
    to,
    image,
    name,
    code,
    totalNowPrice,
    totalWasPrice,
    quantity,
    isUsed,
    stock,
    promotion,
    productType,
    isGift,
  } = product;

  const {
    itemImage,
    isMotorcycleOutOfStock,
    isConfirmationButtonVisible,
    setIsConfirmationButtonVisible,
  } = useCartItem(product, motorcycleStock);

  const [handleIncrement, handleDecrement] = useQuantitySelector(
    quantity,
    MIN_QUANTITY,
    stock.stockQuantity
  );

  const variantDescriptions = GetVariantDescriptions(product);

  const isVendorSparePart = productType === ProductType.VENDORSPAREPARTS;

  const imagePreset = useMemo(() => {
    if (image && (isUsed || isVendorSparePart)) {
      return '?preset=small-product-card-used-desktop';
    } else {
      return '?preset=small-product-card-desktop';
    }
  }, [image, isUsed, isVendorSparePart]);

  return (
    <CartItemContainer isMiniCart={isMiniCart}>
      <ProductTag text={promotion?.promotionName} />
      <CartItemTop>
        <KexLink
          aria-label={name}
          css={{ mb: 0 }}
          href={isVendorSparePart ? '' : to}
          underlined={false}
        >
          <ImageContainer isMotorcycleOutOfStock={isMotorcycleOutOfStock}>
            <ProductImage
              src={`${itemImage.src}${imagePreset}`}
              alt={image?.alt ?? ''}
              isUsed={isUsed}
            />
          </ImageContainer>
        </KexLink>
        <Details>
          <TopWrapper>
            <StyledKexLink
              href={isVendorSparePart ? '' : to}
              underlined={false}
            >
              <ProductCardTitle title={name} />
            </StyledKexLink>
            {allowEdit && !isGift && (
              <RemoveButton
                aria-label={'remove button'}
                onClick={() => {
                  if (needConfirmDelete) {
                    setIsConfirmationButtonVisible(true);
                  } else {
                    RemoveFromCart(code, languageRoute);
                  }
                }}
              >
                <RemoveIcon size="s" color="secondary" />
              </RemoveButton>
            )}
          </TopWrapper>
          <BottomWrapper>
            <div>
              {variantDescriptions && (
                <ProductVariantDescription>
                  {variantDescriptions}
                </ProductVariantDescription>
              )}
              <Price
                friendlyWasPrice={totalWasPrice?.priceRoundedAsString}
                friendlyNowPrice={totalNowPrice?.priceRoundedAsString}
                wasPrice={totalWasPrice?.price}
                nowPrice={totalNowPrice?.price}
                direction="columnReverse"
                nowPriceSize={{ fs: 8 }}
                wasPriceSize={{ fs: 6 }}
              />
            </div>
            {product.productType !== ProductType.MOTORCYCLES && allowEdit && (
              <QuantitySelector
                code={code}
                maxQuantity={stock.stockQuantity}
                minQuantity={MIN_QUANTITY}
                currentQuantity={quantity}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                disabledMin={isGift}
              />
            )}
          </BottomWrapper>
          {isConfirmationButtonVisible && (
            <ConfirmDelete>
              <ConfirmDescription>
                {confirmDeleteOfParentLabel}
              </ConfirmDescription>
              <ButtonWrapper>
                <Button
                  size="s"
                  type="secondary"
                  onClick={() => {
                    setIsConfirmationButtonVisible(false);
                  }}
                >
                  {abortLabel}
                </Button>
                <Button
                  size="s"
                  type="tertiary"
                  onClick={() => {
                    RemoveFromCart(code, languageRoute);
                  }}
                >
                  {removeLabel}
                </Button>
              </ButtonWrapper>
            </ConfirmDelete>
          )}
        </Details>
      </CartItemTop>
      {isMotorcycleOutOfStock && (
        <SoldOut
          soldOut={soldOut}
          soldOutContactMail={soldOutContactMail}
          soldOutContactPhone={soldOutContactPhone}
        />
      )}
    </CartItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  maxW: '80%',
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
});

const ConfirmDelete = styled('div', { marginTop: '32px' });
const ConfirmDescription = styled('div', {
  color: '$textPrimary',
});
const ButtonWrapper = styled('div', {
  display: 'flex',
  marginTop: '16px',
  gap: '16px',
});

const CartItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  '&:not(:last-child)': {
    py: 5,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 5,
    mb: 6,
  },
  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const CartItemTop = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  flexGrow: 1,
});

const ImageContainer = styled('div', {
  width: 104,
  height: 80,
  mr: 4,
  variants: {
    isMotorcycleOutOfStock: {
      true: { opacity: 0.4 },
      false: { opacity: 1 },
    },
  },
});

const TopWrapper = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

const BottomWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

const Details = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const ProductVariantDescription = styled('span', {
  fs: 6,
  display: 'block',
  mb: 2,
  color: '$cartItemText',
});

const RemoveButton = styled('button', {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
});

export default CartItemDesktop;
