import { RemoveIcon } from 'Atoms/Icons';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Price from 'Atoms/Price/Price';
import { RemoveFromCart } from 'Shared/Cart/Cart';
import { ProductType } from 'Enums/ProductType.enum';
import ProductImage from 'Atoms/Image/ProductImage';

import { styled } from 'stitches.config';
import KexLink from 'Kex/KexLink';
import useQuantitySelector from './useQuantitySelector';
import useCartItem from './useCartItem';
import { MIN_QUANTITY } from '../../Shared/Common/QuantityLimit';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Button from 'Atoms/Buttons/Button';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { GetVariantDescriptions } from 'Shared/Common/VariationHelpers';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import SoldOut from './SoldOut';
import MotorcycleStockModel from 'Models/Variant/MotorcycleStockModel.interface';
import ProductTag from 'Atoms/ProductTag/ProductTag';
import { useMemo } from 'react';

type PropTypes = {
  product: VariationModel;
  allowEdit?: boolean;
  isMiniCart?: boolean;
  needConfirmDelete?: boolean;
  motorcycleStock?: MotorcycleStockModel;
};

function CartItemMobile({
  product,
  allowEdit,
  isMiniCart = false,
  needConfirmDelete = false,
  motorcycleStock = {} as MotorcycleStockModel,
}: PropTypes) {
  const { languageRoute } = useAppSettingsData();
  const {
    to,
    image,
    name,
    code,
    totalNowPrice,
    totalWasPrice,
    quantity,
    isUsed,
    stock,
    promotion,
    productType,
    isGift,
  } = product;

  const {
    commonLabels: { abort: abortLabel },
    productLabels: {
      remove: removeLabel,
      confirmDeleteOfParent: confirmDeleteOfParentLabel,
    },
    cartLabels: { soldOut, soldOutContactMail, soldOutContactPhone },
  } = useTranslationData();

  const {
    itemImage,
    isMotorcycleOutOfStock,
    isConfirmationButtonVisible,
    setIsConfirmationButtonVisible,
  } = useCartItem(product, motorcycleStock);

  const [handleIncrement, handleDecrement] = useQuantitySelector(
    quantity,
    MIN_QUANTITY,
    stock.stockQuantity
  );

  const variantDescriptions = GetVariantDescriptions(product);

  const isVendorSparePart = productType === ProductType.VENDORSPAREPARTS;

  const imagePreset = useMemo(() => {
    if (image && (isUsed || isVendorSparePart)) {
      return '?preset=small-product-card-used-desktop';
    } else {
      return '?preset=small-product-card-desktop';
    }
  }, [image, isUsed, isVendorSparePart]);

  return (
    <CartItemContainer isMiniCart={isMiniCart}>
      <ProductTag text={promotion?.promotionName} />
      <CartItemTop>
        <KexLink
          href={isVendorSparePart ? '' : to}
          underlined={false}
          aria-label={name}
        >
          <ImageContainer isMotorcycleOutOfStock={isMotorcycleOutOfStock}>
            <ProductImage
              src={`${itemImage.src}${imagePreset}`}
              alt={image?.alt ?? ''}
              isUsed={isUsed}
            />
          </ImageContainer>
        </KexLink>
        <CartItemContent>
          <StyledKexLink href={isVendorSparePart ? '' : to} underlined={false}>
            <ProductCardTitle title={name} />
          </StyledKexLink>
          {variantDescriptions && (
            <ProductVariantDescription>
              {variantDescriptions}
            </ProductVariantDescription>
          )}
          <Price
            friendlyWasPrice={totalWasPrice?.priceRoundedAsString}
            friendlyNowPrice={totalNowPrice?.priceRoundedAsString}
            wasPrice={totalWasPrice?.price}
            nowPrice={totalNowPrice?.price}
            direction="columnReverse"
            nowPriceSize={{ fs: 6 }}
            wasPriceSize={{ fs: 6 }}
          />
        </CartItemContent>
      </CartItemTop>

      {allowEdit && (
        <>
          <CartItemBottom>
            {!isGift ? (
              <RemoveButton
                onClick={() => {
                  if (needConfirmDelete) {
                    setIsConfirmationButtonVisible(true);
                  } else {
                    RemoveFromCart(code, languageRoute);
                  }
                }}
              >
                <RemoveIcon size="s" color="secondary" />
              </RemoveButton>
            ) : (
              <div></div>
            )}

            {product.productType !== ProductType.MOTORCYCLES && allowEdit && (
              <div>
                <QuantitySelector
                  code={code}
                  maxQuantity={stock.stockQuantity}
                  minQuantity={MIN_QUANTITY}
                  currentQuantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  disabledMin={isGift}
                />
              </div>
            )}
          </CartItemBottom>
          {isConfirmationButtonVisible && (
            <ConfirmDelete>
              <ConfirmDescription>
                {confirmDeleteOfParentLabel}
              </ConfirmDescription>
              <ButtonWrapper>
                <Button
                  size="s"
                  type="secondary"
                  onClick={() => {
                    setIsConfirmationButtonVisible(false);
                  }}
                >
                  {abortLabel}
                </Button>
                <Button
                  size="s"
                  type="tertiary"
                  onClick={() => {
                    RemoveFromCart(code, languageRoute);
                  }}
                >
                  {removeLabel}
                </Button>
              </ButtonWrapper>
            </ConfirmDelete>
          )}
        </>
      )}
      {isMotorcycleOutOfStock && (
        <SoldOut
          soldOut={soldOut}
          soldOutContactMail={soldOutContactMail}
          soldOutContactPhone={soldOutContactPhone}
        />
      )}
    </CartItemContainer>
  );
}

const CartItemContainer = styled('div', {
  position: 'relative',
  '&::not(:last-of-type)::after': {
    content: '""',
    position: 'absolute',
    w: '100%',
    my: 0,
    mx: 'auto',
    display: 'block',
    backgroundColor: '$cartItemBackgroundPrimary',
    h: 0.25,
    bottom: 0,
    opacity: 0.5,
  },
  flexDirection: 'row',
  lineHeight: '$lh15',
  '&:not(:last-child)': {
    pb: 4,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 4,
    mb: 4,
  },
  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const StyledKexLink = styled(KexLink, {
  mb: 4,
  maxW: '80%',
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
});

const ImageContainer = styled('div', {
  width: '104px',
  height: '80px',
  mr: 2,
  variants: {
    allowEdit: {
      true: {
        mb: 6,
      },
    },
    isMotorcycleOutOfStock: {
      true: { opacity: 0.4 },
      false: { opacity: 1 },
    },
  },

  flexShrink: 0,
});

const CartItemContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const CartItemTop = styled('div', {
  display: 'flex',
});

const CartItemBottom = styled('div', {
  mt: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ProductVariantDescription = styled('span', {
  fs: 6,
  color: '$cartItemText',
  display: 'block',
  mb: 2,
});

const RemoveButton = styled('button', {
  width: 96,
  display: 'flex',
  justifyContent: 'center',
});

const ConfirmDelete = styled('div', { marginTop: '32px' });
const ConfirmDescription = styled('div', {
  color: '$textPrimary',
});
const ButtonWrapper = styled('div', {
  display: 'flex',
  marginTop: '16px',
  gap: '16px',
});

export default CartItemMobile;
