import { H4 } from 'Atoms/Typography/Headings/Heading';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import CartItemDesktop from 'Organisms/CartItem/CartItemDesktop';
import CartItemMobile from 'Organisms/CartItem/CartItemMobile';
import useMedia from 'Shared/Hooks/useMedia';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import MotorcycleStockModel from 'Models/Variant/MotorcycleStockModel.interface';
import { GetMotorcycleStock } from 'Shared/Cart/Stock';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useMemo } from 'react';

type PropTypes = {
  products: VariationModel[];
  isLoading?: boolean;
  allowEdit?: boolean;
  isMiniCart?: boolean;
};

type PropTypesCartItem = {
  product: VariationModel;
  allowEdit?: boolean;
  isMiniCart?: boolean;
  isMobile: boolean;
  childKey?: string;
  needConfirmDelete?: boolean;
  motorcycleStock?: MotorcycleStockModel;
};

function setOrAdd<T, S>(map: Map<T, S[]>, key: T, value: S) {
  const existingValues = map.get(key);
  map.set(key, existingValues ? [...existingValues, value] : [value]);
}

type CartEntry =
  | { type: 'singular'; models: VariationModel }
  | { type: 'named'; name: string; models: VariationModel[] }
  | { type: 'parent'; parent: VariationModel; models: VariationModel[] };

const CartItem = ({
  isMobile = true,
  product,
  childKey = '',
  isMiniCart = false,
  allowEdit = false,
  needConfirmDelete = false,
  motorcycleStock,
}: PropTypesCartItem) => {
  return isMobile ? (
    <ItemWrapper isMiniCart={isMiniCart}>
      <CartItemMobile
        key={childKey}
        product={product}
        allowEdit={allowEdit}
        isMiniCart={isMiniCart}
        needConfirmDelete={needConfirmDelete}
        motorcycleStock={motorcycleStock}
      />
    </ItemWrapper>
  ) : (
    <ItemWrapper isMiniCart={isMiniCart}>
      <CartItemDesktop
        key={childKey}
        product={product}
        allowEdit={allowEdit}
        isMiniCart={isMiniCart}
        needConfirmDelete={needConfirmDelete}
        motorcycleStock={motorcycleStock}
      />
    </ItemWrapper>
  );
};

function ProductList({
  products,
  isLoading = false,
  allowEdit = false,
  isMiniCart = false,
}: PropTypes) {
  const {
    commonLabels: { loading },
    productLabels: { partsTo },
  } = useTranslationData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const { motorcycleStock } = GetMotorcycleStock();

  const groupedAndUngroupedProducts = useMemo((): CartEntry[] => {
    const articleNumbers = new Map(
      products.map((p) => [p.articleNumber, p] as const)
    );

    const mp = new Map<string, VariationModel[]>();
    const parents = new Map<VariationModel, VariationModel[]>();
    const others: VariationModel[] = [];

    products.forEach((item) => {
      if (
        !item.belongsToMotorcycleArtNr ||
        item.belongsToMotorcycleArtNr === item.articleNumber
      ) {
        others.push(item);
      } else {
        const parent = articleNumbers.get(item.belongsToMotorcycleArtNr);

        if (parent) {
          if (parent !== item) {
            setOrAdd(parents, parent, item);
          }
        } else {
          setOrAdd(mp, item.belongsToMotorcycleArtNr, item);
        }
      }
    });

    return [
      ...Array.from(
        parents,
        ([key, value]) =>
          ({
            type: 'parent',
            parent: key,
            models: value,
          } as const)
      ),
      ...Array.from(
        mp,
        ([key, value]) =>
          ({
            type: 'named',
            name: key,
            models: value,
          } as const)
      ),
      ...others
        .filter((p) => !parents.has(p))
        .map((p) => ({ type: 'singular', models: p } as const)),
    ];
  }, [products]);

  const renderContent = () => {
    return groupedAndUngroupedProducts
      .filter(
        (entry) =>
          // removes null product and empty parent products
          !!entry.models && (entry.type === 'singular' || !!entry.models.length)
      )
      .map((product, index) => {
        if (product.type === 'singular') {
          const uniqueIdx = product.models.code + product.models.isGift;

          return (
            <CartItem
              key={uniqueIdx}
              childKey={uniqueIdx}
              isMobile={isMobile}
              product={product.models}
              allowEdit={allowEdit}
              isMiniCart={isMiniCart}
              motorcycleStock={motorcycleStock}
            />
          );
        }

        if (product.type === 'named') {
          return (
            <div key={index}>
              <ProductGroupContainer>
                <HeaderWrapper>
                  <ItemWrapper isMiniCart={isMiniCart}>
                    <H4>
                      {partsTo} {product.name}
                    </H4>
                  </ItemWrapper>
                </HeaderWrapper>
                {product.models.map((groupedProduct) => {
                  const uniqueIdx = groupedProduct.code + groupedProduct.isGift;
                  return (
                    <CartItem
                      isMobile={isMobile}
                      childKey={uniqueIdx}
                      key={uniqueIdx}
                      product={groupedProduct}
                      allowEdit={allowEdit}
                      isMiniCart={isMiniCart}
                    />
                  );
                })}
              </ProductGroupContainer>
            </div>
          );
        }

        const uniqueIdx = product.parent.code + product.parent.isGift;

        return (
          <div key={index}>
            <CartItem
              isMobile={isMobile}
              childKey={uniqueIdx}
              key={uniqueIdx}
              product={product.parent}
              allowEdit={allowEdit}
              isMiniCart={isMiniCart}
              needConfirmDelete={true}
              motorcycleStock={motorcycleStock}
            />
            <ProductGroupContainer>
              <HeaderWrapper>
                <ItemWrapper isMiniCart={isMiniCart}>
                  <H4>
                    {partsTo} {product.parent.name}
                  </H4>
                </ItemWrapper>
              </HeaderWrapper>
              {product.models.map((groupedProduct) => {
                const uniqueIdx = groupedProduct.code + groupedProduct.isGift;
                return (
                  <CartItem
                    isMobile={isMobile}
                    childKey={uniqueIdx}
                    key={uniqueIdx}
                    product={groupedProduct}
                    allowEdit={allowEdit}
                    isMiniCart={isMiniCart}
                  />
                );
              })}
            </ProductGroupContainer>
          </div>
        );
      });
  };

  return isLoading ? <p>{loading}</p> : <>{renderContent()}</>;
}

const ProductGroupContainer = styled('div', {
  backgroundColor: '$primary3',
  paddingBottom: '1px',
  mb: 1,
});

const HeaderWrapper = styled('div', {
  paddingTop: '32px',
  paddingBottom: '12px',
});

const ItemWrapper = styled('div', {
  px: 4,
  variants: {
    isMiniCart: {
      true: {
        '@mediaMinLarge': {
          px: 6,
        },
      },
      false: {
        '@mediaMinLarge': {
          px: 8,
        },
      },
    },
  },
});

export default ProductList;
