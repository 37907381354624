import { styled } from 'stitches.config';
import { InfoIcon } from 'Atoms/Icons';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';

type PropType = {
  soldOut: string;
  soldOutContactMail: string;
  soldOutContactPhone: string;
};

const SoldOut = ({
  soldOut,
  soldOutContactMail,
  soldOutContactPhone,
}: PropType) => {
  return (
    <ErrorContainer>
      <Heading>
        <InfoIcon color="error" size="m" />
        <ErrorMessage>
          <ParagraphContainer>
            <Paragraph>{soldOut}</Paragraph>
          </ParagraphContainer>
          <ContactLink href={`mailto:${soldOutContactMail}`}>
            {soldOutContactMail}
          </ContactLink>
          <ContactLink href={`tel:${soldOutContactPhone}`}>
            {soldOutContactPhone}
          </ContactLink>
        </ErrorMessage>
      </Heading>
    </ErrorContainer>
  );
};

const ErrorContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: 6,
});

const Heading = styled('div', {
  display: 'flex',
  gap: 8,
  mb: 4,
});

const ParagraphContainer = styled('div', {
  mb: 4,
});

const ContactLink = styled('a', {
  mb: 1,
  display: 'block',
  color: '$secondary2',
  textDecoration: 'underline solid',
  textUnderlineOffset: '4px',
});

const ErrorMessage = styled('div', {});

export default SoldOut;
